import React, { useRef, useEffect, useState, createRef} from 'react';
import  { FormControlLabel, TextField, Checkbox, Select, MenuItem, Tooltip, IconButton, Badge, Button, FormControl, InputLabel, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Language as LanguageIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'
import {PlateArabic} from './plateArabic';
import { withStyles } from '@material-ui/core/styles';
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import { ProvinceData } from '../../data/platesData';
import { CharsEditor } from './charsEditor';

/**
 * editor for a single annotation
 * @param props 
 * @returns 
 */
export function PlateEditor(props: any) {

  const canvasRef = useRef(null);
  const [value, setValue] = useState(0); // integer state
  const platesRef = useRef();
  let regions: string[] = [];
  // WTT-438 MRR allowed region values
  let townships: string[] = [];

  // pointer to  selected annotation
  const ant = common.plates.selectedRecord?.Annotations[props.index];
  const selected = ant === common.plates.selectedAnnotation;
  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setPlateThumbnail(props.index, canvas);
    return () => {
      platesService.canvas.setPlateThumbnail(props.index, null);
    };
  });

  // annotation properties
  const fields = ["PlateNumber","WhiteChars","Bilingual","PlateType","Nation","VehicleType",
  "ExtraType","Category","CharHeight","Poly","Rect","InternalPoly"];


  /**
   * regions in function of nation
   * @param nation 
   */
  const updateRegions = () => {
    try {
      const nation = ant.Nation.value;
      const nationRegs = common.plates.regions.find(r => r.nation === nation);
      regions = nationRegs && nationRegs.regions ? nationRegs.regions : [];
      townships = nationRegs && nationRegs.townships ? nationRegs.townships : [];
      // setRegions(regs);
    } catch (ex) {
      console.error('failed to update regions:')
    }
  }
 
  /**
   * update status attribute for the specified field
   * @param field 
   */
  const updateTagStatus = (field: string) => {
    try {
      if (fields.find(f => f === field)) {
        if (ant[field].status !== 'tagged') {
          ant[field].status = 'tagged';
          setValue(v => v + 1);
        }
      }

    } catch (ex) {
      console.error('failed to updateTagStatus:', ex);
    }
  }

  /**
   * remove all pre tags
   */
  const clearPreTags = () => {
    try {
      if (ant.Accepted === true)
        fields.forEach(f => ant[f].status = 'tagged');

    } catch (ex) {
      console.error('failed on clearPreTags:', ex);
    }
  }

  /**
   * using is sending chars from the virtual keyboard 
   * handle only if annotation is selected
   * @param ch 
   */
  const handleKeyboardChar = (ch: string) => {
    try {
      const selected = common.plates.selectedAnnotation;
      if (ant === common.plates.selectedAnnotation) {
        switch (ch) {
          case "Backspace":
            if (ant.PlateNumber.value.length > 0)
              ant.PlateNumber.value =  ant.PlateNumber.value.substring(0, ant.PlateNumber.value.length - 1);
            break;

          case "Clear":
            ant.PlateNumber.value = '';
            break;

          default: 
            const pos = (platesRef.current as any).selectionStart;
            if (pos === 0) {
              ant.PlateNumber.value += ch;
            } else {
              let val = ant.PlateNumber.value;
              val = [val.slice(0, pos), ch, val.slice(pos)].join('');
              ant.PlateNumber.value = val;
            }
          break;
        }
        setValue(v => v + 1);        
      }

    } catch (ex) {
      console.error('failed on handle Keyboard char:', ex);
    }
  }

  const keyboardBackspace = (ch: string) => {
    try {
      if (ant === common.plates.selectedAnnotation) {
        ant.PlateNumber.value += ch;
        setValue(v => v + 1);
      }

    } catch (ex) {
      console.error('failed on handle Keyboard char:', ex);
    }
  }

  const keyboardClear = (ch: string) => {
    try {
      if (ant === common.plates.selectedAnnotation) {
        ant.PlateNumber.value += ch;
        setValue(v => v + 1);
      }

    } catch (ex) {
      console.error('failed on handle Keyboard char:', ex);
    }
  }

  /**
   * global callbacks handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch (key) {
        case "SimulatePlateNumber":
        case "PlateNumber":
          ant.PlateNumber.value = (value as string).toUpperCase();
          common.notify('PlateNumberChanged');
          updateTagStatus(key);
          common.notify('AnnotationChanged');

          // WTT-421
          if (selected)
            common.notify('CharsReset');
          break;

        case "SimulatePlateType":
        case "PlateType":
          value = parseInt(value);
          if (value >= 40) {
            ant.PlateType.value = 3;
            ant.Nation.value = 'Unlisted';
            ant.Region.value = '';
            ant.Category.value = '';
            ant.VehicleType.value = 6;
            ant.PlateNumber.value = value.toString();
            common.notify('PlateNumberChanged');
            common.notify('AnnotationChanged');
            common.notify('AnnotationNationChanged');
            setValue(v => v + 1);
            return;
          }

          ant.PlateType.value = value;
          updateTagStatus(key);
          switch(value) {
            // diamond, adr, empty adr (speed)
            case 2:
            case 3:
            case 4:
            case 6:
              ant.VehicleType.value = 6;
              ant.Nation.value = 'Unlisted';
              ant.Region.value = '';
              ant.Category.value = '';
              break;

            // trailer
            case 1:
              ant.VehicleType.value = 6;
              break;
          }
          common.notify('AnnotationChanged');
          common.notify('AnnotationNationChanged');
          setValue(v => v + 1);
          break;

        case "SimulateVehicleType":
        case "VehicleType":
          ant.VehicleType.value = value;
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          break;

        case "SimulateNation":
        case "Nation":
          ant.Nation.value = value;
          ant.Region.value = '';
          // ofer WTT-331 - clear plate face when nation !== 'ARE'
          if (value !== 'ARE'  && value !== 'SAU' && value !== 'QAT' && ant.Category)
            ant.Category.value = '';

          // WTT-404 - note - in the future expect a list of bilingual nations
          ant.Bilingual.value = value === 'OMN' ? true : false;

          // updateRegions();
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          common.notify('AnnotationNationChanged');
          break;

        case "SimulateRegion":
        case "Region":
          ant.Region.value = value;
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          break;

        case "SimulateWhiteChars":
        case "WhiteChars":
          ant.WhiteChars.value = value;
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          break;

        case "SimulateBilingual":
        case "Bilingual":
          ant.Bilingual.value = value;
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          break;

        case "SimulateExtraType":
        case "ExtraType":
          ant.ExtraType.value = parseInt(value);
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          // WTT-421B - verify modification of unselected plate
          if (selected)
            common.notify('CharsResetExtra');
          break;

        case "Cat":
          ant.CatType.value = value;
          updateTagStatus(key);
          common.notify('AnnotationChanged');
          break;

        case "OK":
          ant.Accepted = value;
          clearPreTags();
          setValue(v => v + 1);
          updateTagStatus(key);
          common.notify('AnnotationChanged');
        break;

        case "PlatesKeyboardChar":
          handleKeyboardChar(value);
          break;

        case 'OpenArabicTab':
          window.open(common.plates.charPageUrl);
          break;

        case 'Province':
          ant.Region.value = value;
          setValue(v => v + 1);
          break;

        case 'Township':
          ant.Region.value = trueMrrRegion() ? ant.Region.value.substring(0,3) + value : value;
          setValue(v => v + 1);
          break;

        case 'MrrRegion':
          ant.Region.value = value === regions[0] ? '': value;
          setValue(v => v + 1);
          break;


      }
 
    } catch (ex) {
      console.error( ` failed to handle plateEditor changes: ${key}`, ex);
    }
  }




  /**
   * cutout image was clicked - used for image selection
   */
  const handleImageClick = () => {
    try {
      common.unselectAnnotations();
      common.plates.selectedAnnotation = ant;
      common.notify("AnnotationSelected", ant);
    } catch (ex) {
      console.error('failed to handle selection:', ex);
    }
  }

  const rectPreTagged = () => {
    try {
      const preTagged =  ant.CharHeight?.status === 'pre-tagged' || 
      ant.Poly.status === 'pre-tagged' ||
      ant.InternalPoly.status === 'pre-tagged';
      return preTagged ? 1 : 0;
    } catch (ex) {
      console.error('failed on rectPreTagged', ex);
      return 0;
    }
  }

  /**
   * determine wether the internal poly is in default status
   * @returns 
   */
  const getInternalPolyStatus = () => {
    try {
      var v = ant.InternalPoly?.value;
      if (!v)
        return "Not set";
        
      var p = [0,1,1,0];
      var set = false;
      for (let i = 0; i < 4; i++)
        if (p[i] !== v[i])
          set = true;
      return set ? "Set": "Not set";
    } catch (ex) {
      console.error('failed on getInternalPolyStatus:', ex);
    }

  }

  /**
   * show or hide arabic chars panel below plate number
   * @returns 
   */
  const showArabicChars = (): boolean => {
    try {
      const arabics = common.plates.arabicNations;
      if (!arabics)
        return false;

      const nation = ant?.Nation?.value;
      if (!nation)
        return false;

      return arabics.includes(nation);

    } catch (ex) {
      console.error('failed to show arabic chars:', ex);
      return false;
    }
  }

  const showThaiProvices = (): boolean => {
    try {
      return ant?.Nation?.value === 'THA';
    } catch (ex) {
      console.error('failed to show thai provices:', ex);
      return false;
    }
  }

  /**
   * are there any pretag fields
   * @returns 
   */
  const preTagged = (): boolean => {
    try {
      return  undefined !== fields.find(f => ant[f]?.status === 'pre-tagged');

    } catch (ex) {
      console.error('failed on preTagged', ex);
      return false;
    }
  }

  /**
   * get the border color and width 
   * @returns 
   */
  const getBorder = () => {
    try {


      if (ant.Accepted)
        return "limegreen solid 3px";

      var preTagged = fields.find(f => ant[f]?.status === 'pre-tagged');
      if (preTagged)
        return "orange solid 3px";

      if (ant === common.plates.selectedAnnotation)
        return "blue solid 2px";

      return "gray solid 1px";
    } catch (ex) {
      console.error('failed to get border:', ex);
      return "red solid 5px";
    }
  }

  /**
   * extract township associated with the region
   * @returns 
   */
  const getTownshipRange = () => {
    try {
      if (!MMR) return '';
      // extract the region part from regionTownship composition
      let r = ant?.Region?.value || '';
      r = r.substring(0,3);
      if (r === '?') return ''; // jolly - no range
      const rIndex = regions.indexOf(r);
      if (rIndex < 0) return '';
      const range = townships[rIndex];
      return range ? `(${range})` : '';
    } catch (ex) {
      console.error('failed to get township:', ex);
      return '';
    }
  }

  const getTownshipError = () => {
    try {
      const reg = ant?.Region?.value || '';
      const r = reg.substring(0,3);
      const rIndex = regions.indexOf(r);
      if (rIndex <= 0) return false;
      const range = townships[rIndex];
      const low = parseInt(range.split('-')[0]);
      const high = parseInt(range.split('-')[1]);
      const val = Number(reg.substring(3));
      if (isNaN(val)) return true;

      return val < low || val > high;
    } catch (ex) {
      console.error('failed to get township error:',ex);
      return false;
    }
  }

  const trueMrrRegion = () => {
    try {
      const r = ant?.Region?.value || '';
      const prefix = r.substring(0,3);
      return regions.indexOf(prefix) > 0;

    } catch (ex) {
      console.error('failed on trueMrrRegion:', ex);
      return false;
    }
  }

  const getMrrRegion = () => {
    try {
      const r = ant?.Region?.value || '';
      const prefix = r.substring(0,3);
      if (regions.indexOf(prefix) > -1) 
        return prefix;
      return regions[0];
    } catch (ex) {
      console.error('failed to get mrr region:', ex);
    }
  }

  const getMrrTownship = () => {
    try {
      const r = ant?.Region?.value || '';
      return trueMrrRegion() ? r.substring(3) : r;
    } catch (ex) {
      console.error('failed to get mrr region:', ex);
    }
  }

  /**
   * control is disabled
   */
  const isDisabled = (ant:any, field:string) => {
    const tagType = common.plates.newTagType;
    try {
      switch(field) {
        case 'Bilingual':
        case 'Province':
        case 'ExtraType':
        case 'VehicleType':
        case 'PlateType':
        case 'WhiteChars': return tagType === 'Chars';

        case 'Nation':
        case 'Region': return  tagType === 'Chars' || ant.PlateType > 0;

      }

      return false;
    } catch (ex) {
      console.error('failed on plate edit disabled:', ex);
      return true;
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  // show tooltips 
  const tooltips = common.plates.settings.tooltips;
  const classes = useStyles();

  updateRegions();

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);



  const provinces = common.plates?.thaiProvinces || [];
  const tagType = common.plates?.newTagType || false;

  // WTT-417 - hide diamond unless annotation is already diamond (historic annotation)
  const diamond = ant.PlateType?.value === 4;
  const plateTypesUnfiltered = common.plates.plateTypes || [];
  const plateTypes = diamond ? plateTypesUnfiltered : plateTypesUnfiltered.filter(p => p.value !== 4);


  // WTT-417 - show tagging details if new tag type is OCR
  const showDetails = common.plates.newTagType === 'Ocr' || common.plates.newTagType === 'Chars';
  // WTT-438 - shotcut to MRR country
  const MMR = ant?.Nation?.value === 'MMR';

  const townshipRange = getTownshipRange();
  

  return <Box display="flex" flex="1" flexDirection="row" 
  style={{border: getBorder(), margin: 4}}>

      <Box display="flex" flexDirection="column" padding="4px">

      <Tooltip title= {tooltips ? "Plate cutout": ''} placement="left">
      <Badge color="error"  variant="dot" 
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            badgeContent={ant?.Poly?.status === "pre-tagged" ? 1 : 0} >
        <canvas  ref={canvasRef} 
        onMouseDown={e => handleImageClick() }
          width={200} height={70} style={{backgroundColor: 'teal', marginBottom: '6px', cursor: 'pointer'}}>
        </canvas>
          </Badge>
          </Tooltip>

          <Box display="flex" flexDirection="column">

          <Box display="flex" flexDirection="row">
            <Tooltip title= { tooltips ? "Character height" :''} placement="left">
            <Badge color="error"  variant="dot" 
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            badgeContent={ant.CharHeight?.status === "pre-tagged" ? 1 : 0} >
            Height: {ant.CharHeight?.value} 
            </Badge>
            </Tooltip>
            <Box width={10}></Box>

            <Tooltip title={ tooltips ? "Internal polygon state" : '' } placement="left">
            <Badge color="error"  variant="dot" 
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            badgeContent={ant.InternalPoly?.status === "pre-tagged" ? 1 : 0} >
            Internal:{ getInternalPolyStatus() }
            </Badge>
            </Tooltip>
            </Box>


      { tagType === 'Ocr' && 
          <Tooltip title={ tooltips ? "Plate number" : '' } placement="left">
          <Badge color="error"  variant="dot" 
          anchorOrigin={{horizontal: 'left', vertical: 'top'}}
          badgeContent={ant.PlateNumber?.status === "pre-tagged" ? 1 : 0} >
          <TextField label="Plate number" inputRef={platesRef}
          value={ant.PlateNumber.value}
          onChange={ e => handleChanges("PlateNumber", e.target.value)}
          style={{margin: 0}}></TextField>
          </Badge>
          </Tooltip>
      }

      { tagType === 'Chars' && <CharsEditor ant={ant}></CharsEditor> }

           

          { showArabicChars() &&
            <PlateArabic plateNumber={ant.PlateNumber.value} nation={ant.Nation?.value || ''} style={{marginTop:1}}></PlateArabic>
          }

          <Box display="flex" flexDirection="row">
          <Tooltip title={tooltips ? "Delete annotation" : ''}>
            <IconButton  
            onClick = {() =>  common.notify('DeleteAnnotation', ant)}
              color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>

        <Box display={preTagged() ? "flex": "none"}>            
        <Tooltip title={ tooltips ? "Override pre-tagging" : '' } placement="right">
        <FormControlLabel  className={classes.formControl} value="AB" 
        style={{margin:10}}
        control={<Checkbox 
          checked={ant.Accepted || false}
          onChange={e => handleChanges("OK", e.target.checked)}
          className={classes.radio} />} label="OK" />
          </Tooltip>
          </Box>
          </Box>
        </Box>
      </Box>

      <Box display= {showDetails ? "flex" : "none"}  flexDirection="column" marginLeft={2}>
        
            <Box display="flex" flexDirection="row" alignItems="center">


              <Box display="inline" style={{width:80}}>Plate: </Box>

            <Tooltip title={tooltips ? "Plate type type":''} placement="left">
          <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.PlateType?.status === "pre-tagged" ? 1 : 0} >
        <Select className={classes.combo}   label="Plate type:"
          disabled={isDisabled(ant, 'PlateType')}
          value={ant.PlateType?.value || 0} 
          onChange={e =>handleChanges("PlateType", e.target.value)}>
              {plateTypes.map((pt:any) => ( <MenuItem value={pt.value}>{pt.name}</MenuItem>))}
        </Select>   
        </Badge>  
        </Tooltip>

            </Box>

            <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="inline" style={{width:80}}>Vehicle:</Box>
   
        <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.VehicleType?.status === "pre-tagged" ? 1 : 0} >
        <Select className={classes.combo} 
          disabled={isDisabled(ant,'VehicleType')}
          value={ant.VehicleType?.value || 0} 
          onChange={e =>handleChanges("VehicleType", e.target.value)}>
              {common.plates.vehicleTypes?.map((vt:any) => ( <MenuItem value={vt.value}>{vt.name}</MenuItem>))}
        </Select>
        </Badge>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="inline" style={{width:80}}>Extra: </Box>

        <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.ExtraType?.status === "pre-tagged" ? 1 : 0} >
        <Select className={classes.combo} 
          disabled = {isDisabled(ant,'ExtraType')}
          value={ant.ExtraType?.value || 0} 
          onChange={e =>handleChanges("ExtraType", e.target.value)}>
              {common.plates.extraTypes?.map(pt => ( <MenuItem value={pt[0]}>{pt[1]}</MenuItem>))}
        </Select>
        </Badge>
 
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="inline" style={{width:80}}>Nation: </Box>
   
        <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.Nation?.status === "pre-tagged" ? 1 : 0} >
        <Select className={classes.combo} 
          disabled= {isDisabled(ant,'Nation')}
          value={ant.Nation.value || ''} 
          onChange={e =>handleChanges("Nation", e.target.value)}>
              {common.plates.nations?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
        </Select>
        </Badge>
  
        </Box>
{/* WTT-438 */}
        {MMR && <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="inline" style={{width:65}}>Region: </Box>
            <Badge color="error"  variant="dot" 
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        badgeContent={ant.Region?.status === "pre-tagged" ? 1 : 0} >
          <Select style={{width:'68px'}}
            disabled={isDisabled(ant, 'Region')}
            value={getMrrRegion()} 
            onChange={e =>handleChanges("MrrRegion", e.target.value)}>
                {regions?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
          </Select>
          </Badge>

          <Box width="4px"/>

          <TextField 
          value={getMrrTownship()}
          error={getTownshipError()}
          onChange={ e => handleChanges("Township", e.target.value)}
          style={{margin: 0, width:'51px'}}></TextField>

          <Typography style={{fontSize:'12px', fontWeight:'bold'}}>{townshipRange}</Typography>

          </Box>}

        <Box display={regions.length === 0 || MMR  ? "none" : "flex"}  flexDirection="row" alignItems="center">
          <Box display="inline" style={{width:80}}>Region: </Box>
   
        <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.Region?.status === "pre-tagged" ? 1 : 0} >
        <Select className={classes.combo} 
          disabled={isDisabled(ant, 'Region')}
          value={ant.Region?.value || ''} 
          onChange={e =>handleChanges("Region", e.target.value)}>
              {regions?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
        </Select>
        </Badge>
  
        </Box>

        { showThaiProvices() && <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="inline" style={{width:80}}>Province:</Box>
          <Box display="inline" style={{background:'lightgray'}} width="40px" minWidth="30px">{ant?.Region?.value}</Box>
          &nbsp;
          <Select value={ant?.Region?.value || ''}
          disabled= {isDisabled(ant, 'Province')}
          onChange={(e) => handleChanges("Province", e.target.value)} style={{width:'170px'}}>
            {provinces.map((prv:ProvinceData) => <MenuItem value={prv.abbreviation}>{prv.iso} {prv.localName}</MenuItem>)}
          </Select>
          </Box>}

        <Box display="flex" flexDirection="row" marginTop={1}>
  
      <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.WhiteChars?.status === "pre-tagged" ? 1 : 0} >
        <FormControlLabel className={classes.formControl} value="AB" 
          control={<Checkbox 
            disabled = {isDisabled(ant, 'WhiteChars')}
            checked = {ant.WhiteChars?.value || false} 
            onChange={e => handleChanges("WhiteChars", e.target.checked)} 
          className={classes.radio} />} label="White" />
          </Badge>
 

       
          <Badge color="error"  variant="dot" 
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      badgeContent={ant.Bilingual?.status === "pre-tagged" ? 1 : 0} >
        <FormControlLabel className={classes.formControl} value="AB" style={{marginLeft:8}}
          control={<Checkbox 
          disabled = {isDisabled(ant, 'Bilingual')}
          checked={ant.Bilingual?.value || false}
          onChange={e => handleChanges("Bilingual", e.target.checked)}
          className={classes.radio} />} label="Bilingual" />
          </Badge>

          { common.plates.charPageUrl && 
          <Tooltip title={tooltips ? "Show chars map" : ""}>
            <IconButton onClick={() => handleChanges('OpenArabicTab', null)}><LanguageIcon/></IconButton>
          </Tooltip>
          }

        </Box>

        <Box display="inline" marginTop={1} marginBottom={1}>
        <Tooltip title={tooltips ? "Plate face":''} placement="right">
          <Badge color="error"  variant="dot" 
          anchorOrigin={{horizontal: 'left', vertical: 'top'}}
          badgeContent={ant.Category?.status === "pre-tagged" ? 1 : 0} >
          Face:{ant?.Category?.value}
          </Badge>
        </Tooltip>
        </Box>

      
      </Box> 

      
      <Box display= {selected ? "flex" : "none"}  width={20} ></Box>

      <Box display= {selected ? "flex" : "none"}  flexDirection="column">
  
      <Box display= {selected ? "flex" : "none"}  height={5} ></Box>

      <Box display="Flex" flexDirection="row">

  
        </Box>

     

        

        <Box display= {selected ? "flex" : "none"}  height={10} ></Box>

        <Box display="flex" flex="1"></Box>

       
            <Box display="flex" flexDirection="row">
      
          </Box>
        
      </Box>

      <Box display= {selected ? "flex" : "none"}  width={10} ></Box>

   
     
    </Box>
  

}

const useStyles = makeStyles({
  button: {

  },
  radio: {
    margin: 0,
    padding: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  combo: {
    width: 150,
  },
  badge: {
    top: 0,    
  }

});