import { Filter } from '@material-ui/icons';
import { truncateSync } from 'fs';
import plate from '../components/inside/editors/plate';
import common from './commonService';
import { PlatesCanvas } from "./platesCanvas";
import { PlatesStatistics } from './platesStatistics';
import { isArray, isNumber } from '@material-ui/data-grid';
import { PlatesCycler } from './platesCycler';
import axlesService from './axlesServices';
import platesVehicles  from './platesVehicles';
import insideInspection from './insideInspection';
import { InsideEntry, InsideItem, PlateMatch, ProvinceData, VehicleBounding } from '../data/platesData';
import copy  from 'copy-to-clipboard';
import insideService from './insideService';
import arabicConverter from './arabicConverter';
import lightsService from './lightsService';
import hazardService from './hazardService';
import charsService from './charsService';
import reflectiveService from './reflectiveService';
const axios = require ('axios');




/**
 * plates service provider
 */

export class PlatesService {

  // data: PlatesServiceData;
  canvas: PlatesCanvas;
  selectedRowIndex: number;
  statistics: PlatesStatistics = new PlatesStatistics();
  cycler: PlatesCycler = new PlatesCycler();

  /**
   * constructor
   */
  constructor() {
    // this.data = new PlatesServiceData();
    this.canvas = new PlatesCanvas();
    this.selectedRowIndex = 0;
    this.loadSettings();
    // this.loadGuides();
    this.loadStaticResources();

    insideInspection.initialize();
    //this.extraInfoManager();

    common.notifier$.subscribe(msg => {
      switch (msg.name) {
        case "LaneSettingsChanged":
        case "AnnotationAdded":
        case "AnnotationDeleted":
        case "VehicleBoudingDeleted":
        case 'InsideDeleted':
        case 'LightDeleted':
        case 'LightChanged':
        case 'HazardDeleted':
        case 'ReflectiveDeleted':
        case 'HazardAnnotationChanged':
        case "VehicleBoudingChanged":
        case "VehicleBoundingAdded":
        case 'InsideAdded':
        case 'InsidePassengersClosed':
        case 'InsideOccupiedChanged':
        case 'HazardAdded':
        case 'ReflectAdded':
        case 'SelectedCharIndexChanged':
        case 'CharsPolyChanged':
        case 'CharsSelected':
        this.canvas.pendingPaint = true;
        this.validateAnnotations();
        break;

        // WTT-417
        case 'PlatesTagTypeChanged2':
          this.handleTaggingType();
          break;

        case 'AnnotationChanged':
          this.validateAnnotations();
          break;
        
        
        case "GeneratePlatesFile":
          this.generatePlatesfile(msg.data);
          break;

        case "PlatesClearCanvas":
          this.canvas.clear();
          break;

        case "SaveSettings":
          this.saveSettings();
          break;

        case 'PlateSettingsChanged':
          this.canvas.pendingPaint = true;
          this.updateTagTypes();
          this.saveSettings();
          this.validateAnnotations();
          break;

        case 'CancelCurrentAnnotation':
          this.canvas.cancelCurrentPoly();
          break;

        case "PlatesUpdateFilter":
        case "PlatesFilterChanged":
            this.updatePlatesFilter();
          break;

        case 'PlatesGetDatasets':
          this.getDatasets();
          break;

        case "PlatesLoadDataset":
          this.loadDataset(msg.data as string);
          break;

        case "PlatesGetRecord2":
          this.getRecord2(msg.data as string);
          break;

        case "PlatesSelectionChanged":
          this.handleSelectionChanged(msg.data as string);
          break;

        case 'PlatesCancelChanges':
          this.handleCancelChanges(msg.data as string);
          break;

        case "PlatesSaveRecord":
          this.saveRecord2();
          break;

        case "PlatesUnzoom":
          this.canvas.unzoom();
          break;

        case "PlatesSaveState":
          this.saveState();
          break;

        case "StartPlateStatistics":
          this.statistics.start(msg.data as string);
          break;

        case "StopPlatesStatistics":
          this.statistics.stop();
          break;

        case "PlatesDatasetsFilterChanged":
          this.updateDatasetsFilter();
          break;

        // case "PlatesEgyptize":
        //   this.egyptize();
        //   break;

        case "InsideAnnotationChanged":
        case "PlateNumberChanged":
        case "InternalPolyChanged":
          this.validateAnnotations();
          break;

        case "CharHeightChanged":
          this.validateAnnotations();
          break;

        case "PlatesCopyListToClipboard":
          this.copyPlateListToClipboard();
          break;

        case "PlatesUpdateCurrentRow":
          this.updateCurrentRow();
          break;

        case "SelectedPlateChanged":
        case "AnnotationNationChanged":
          this.handleNationChanged();
          break;

        case "DownloadRawImage":
          this.downloadRawImage();
          break;

        case "AnnotationSelected":
          this.canvas.pendingPaint = true;
          break;

        case "PlatesForceScan":
          this.forceScan();
          break;

        case 'PromoteSelectedAnnotation':
          this.promoteSelectedAnnotation();
          break;

      }});
  }

  /**
   * 
   */
  handleTaggingType() {
    try {
      common.unselectAnnotations();
      this.canvas.pendingPaint = true;
      // select first annotation in the selected group
      this.selectFirstAnnotation();
    } catch (ex) {
      console.error('failed to handle tagging type:', ex);
    }
  }

  /**
   * load settings from local storage
   */
  loadSettings = () => {
    try {
      const item = localStorage.getItem('plates.settings');
      if (item) {
        const settings = JSON.parse(item);
        // WTT-308 handle backward configuration
        if (settings.tagOcr)
          settings.showPlate = false;

        settings.pureImageLib = true;
        settings.preTagging = true;
        common.plates.settings = settings;
        common.axles.stitchTrim = settings.axlesStitchTrim || 0;        
      }
      common.notify('PlatesSettingsLoaded');
      

    } catch (ex) {
      console.error('failed to load plate settings:', ex);
    }
  }

  /**
   * save application settings to local storage
   */
  saveSettings = () => {
    try {
      const settings = common.plates.settings;
      const item = JSON.stringify(settings);
      localStorage.setItem('plates.settings', item); 
    } catch (ex) {
      console.error('failed to savePlateSettings plate settings:', ex);
    }
  }

  /**
   * cache state in local storage
   */
  saveState = () => {
    try {
      const state = common.plates.state;
      
      const item = JSON.stringify(state);
      localStorage.setItem('plates.state', item);
    } catch (ex) {
      console.error('failed to save state:', ex);
    }
  }

  /**
   * load cached state from local storage
   */
  loadState = () => {
    try {
      const item = localStorage.getItem('plates.state');
      if (item) {
        const state = JSON.parse(item);
        common.plates.state = state;
      }

    } catch (ex) {
      console.error('failed to load state:', ex);
    }
  }

getFirstImageInfo = async (dataset: any) => {
  try {

    const serverUrl =  process.env.REACT_APP_SERVER_URL;
    let images = dataset.images;
    if (dataset.sequences && dataset.sequences.length > 0) {
      const seqId = dataset.sequences[0];
      const seqUrl = `${serverUrl}/sequence/${seqId}`;
      try {
        const reply = await axios.get(seqUrl, {headers: {'Authorization': common.loginToken}});
        const sequence = reply.data;
      } catch (ex) {
        common.relogIfTokenExpired(ex);
        console.error(`failed to get sequence: ${seqId}`);
      }

    }

    if (!images || !isArray(images))
      return null;
    
    const imageId = images[0];
    if (!imageId)
      return null;
    const url = `${serverUrl}/items/${imageId}`;
    const details = await axios.get(url, {headers: {'Authorization': common.loginToken}});
    return details?.data?.image_library?.info;

  } catch (ex) {
    common.relogIfTokenExpired(ex);
    console.error( `failed to get first image info` , ex);
  }
}

/**
 * restituisce country dal info
 * @param info 
 * @returns 
 */
getCountryFromInfo = (info: any) => {
  try {

    // WTT-89
    // Se country_identified != vuoto allora se country_deploy == country_identified -> uso quel valore
    // Altrimenti controllo che country_deploy sia effettivamente uno stato
    if (!info)
      return '...';

    if (info.country_identified && info.country_identified === info.country_deploy)
      return info.country_identified;

    const nations = common.plates.nations;

    if (nations.includes(info.country_deploy))
      return info.country_deploy;

    if (nations.includes(info.country_identified))
      return info.country_identified;

    return '...';

  } catch (ex) {
    console.error('failed to get country from info: ')
    return '...';
  }
}

/**
 * update list of datasets, based on filter settings
 */
updateDatasetsFilter = () => {
  try {
    common.plates.datasets = common.plates.unfilteredDatasets.filter(ds => this.datasetPassed(ds));


  } catch (ex) {
    console.error('failed to update datsets filter:', ex);
  }
}

/**
 * determine if a dataset 
 * @param ds 
 * @returns 
 */
datasetPassed = (ds:any): boolean => {
  try {
    const nameFilter = common.plates.datasetsFilter.nameFilter;
    const country = common.plates.datasetsFilter.country;
    if (country !== 'ALL' && country !== ds.country)
      return false;


    if (!nameFilter || !ds.alias)
      return true;

    return ds.alias.toUpperCase().includes(nameFilter.toUpperCase());
  } catch (ex) {
    console.error('failed on dataset passed: ', ex);
    return false;
  }
}

getCount = (ds: any) => {
  try {
    if (ds.images)
      return ds.images.length;

    if (ds.sequences)
      return ds.sequences.length;

    return 0;

  } catch (ex) {
    console.error('failed to get dataset count:', ex);
  }

} 

  
/**
 * rerturns a list of datasets
 */
getDatasets = async () => {
  try {
    common.axles.scanPolicy.quitRequest();
    common.plates.scanPolicy.quitRequest();
    common.plates.gettingDatasets = true;
    common.plates.abortGettingDatasets = false;
    common.notify("GettingDatasetsChanged");
    common.plates.datasets = [];
    common.plates.unfilteredDatasets = [];
    const serverUrl =  process.env.REACT_APP_SERVER_URL;
    const datasetsUrl = `${serverUrl}/dataset?dataset.status=active`;
    const response = await axios.get(datasetsUrl, {headers: {'Authorization': common.loginToken}});
    var datasets = response.data;
    
    // WTT-181 - cache datasets - for searching
    common.plates.rawDatasets = datasets;
    // WTT-181 - SHORTCUT
    common.notify('GenerateDatasetsTree', datasets);

 

    const filter = common.plates.datasetsFilter;
    const countries = new Set<string>();
    datasets = datasets.map((ds:any, index:number) => (
      { id: ds._id, count: this.getCount(ds),
        alias: ds.dataset?.alias, statistics: {}}));

   // WTT-181 - PATCH
   common.plates.datasets = datasets;
   common.plates.unfilteredDatasets = datasets;
   filter.countries = ["ALL"];
   countries.forEach( v => filter.countries.push(v));
   common.notify('PlatesDatasetsArrived');
   return;


    

    if (common.plates.abortGettingDatasets)
      return;
  
    for (let i  = 0; i < datasets.length; i++) {

      if (common.plates.abortGettingDatasets)
        return;

      const ds = datasets[i];
      const url = `${serverUrl}/dataset/${ds.id}`;
      const data = await axios.get(url, {headers: {'Authorization': common.loginToken}});
      ds.tagged = false;
      ds.axleCounter = false;
      ds.count = 0;

      // WTT-176 22/11/2021 - give priority to OCR
      const ocrs = data.data?.images?.length || 0;
      const sequences = data.data?.sequences?.length || 0;
      const axles = ocrs === 0 && sequences > 0;
      ds.count = axles ? sequences : ocrs;
      ds.axleCounter = axles;

      ds.alias = data.data?.dataset?.alias;
      ds.application = data.data?.dataset?.application;
      const info = await this.getFirstImageInfo(data.data);
      ds.country = this.getCountryFromInfo(info); //  info?.country_deploy;
      if (ds.country)
        countries.add(ds.country);
      ds.info = null;

      common.plates.getDatasetsPercentage = Math.round(100 * i / datasets.length);
      common.notify('GetDatasetsPercentageChanged');
    }

    common.plates.getDatasetsPercentage = 100;
    common.notify('GetDatasetsPercentageChanged');


    common.plates.datasets = datasets;
    common.plates.unfilteredDatasets = datasets;
    filter.countries = ["ALL"];
    countries.forEach( v => filter.countries.push(v));
    common.notify('PlatesDatasetsArrived');
  
  } catch(ex) {
    common.relogIfTokenExpired(ex);
    console.error('failed to get datasets: ', ex, common.loginToken);
    console.error(`${process.env.REACT_APP_SERVER_URL}/dataset`);
  } finally {
    common.plates.gettingDatasets = false;
    common.notify("GettingDatasetsChanged");
  }
}

updatSettings = (alias: string) => {
  try {

    const settings = common.plates.settings;
    const aliasChanged = alias !== settings.datasetName;
    common.plates.settings.datasetName = alias;
    if (aliasChanged) {
      settings.tagAxle = false;
      settings.tagCab = false;
      settings.tagOcr = false;
      settings.tagVehicle = false;
      settings.tagHazard = false;
      settings.tagChars = false;
      settings.tagInside  = false;
      settings.tagLights = false;
      settings.tagReflective = false;
      common.axles.newTagType = '';
      common.plates.newTagType = '';
    }
    this.updateTagTypes();
  } catch (ex) {
    console.error('failed to update settings:', ex);
  }
 
  common.notify('SaveSettings');
}

  /**
   * update tag types
   */
   updateTagTypes = () => {
    try {
      const settings = common.plates.settings;
      const canTag = [];
      if (settings.tagOcr) canTag.push('Ocr');
      if (settings.tagAxle) canTag.push('Axle');
      if (settings.tagCab) canTag.push('Cab');
      if (settings.tagHazard) canTag.push('Hazard');
      if (settings.tagChars) canTag.push('Chars');
      if (settings.tagInside) canTag.push('Inside');
      if (settings.tagLights) canTag.push('Lights');
      if (settings.tagVehicle) canTag.push('Vehicle');
      if (settings.tagReflective) canTag.push('Reflective');

      if (canTag.includes(common.plates.newTagType))
        return;

      if (canTag.length === 0) {
        common.plates.newTagType = '';
        return;
      }
      common.plates.newTagType = canTag[0];
      common.notify('PlatesTagTypeChanged');
    } catch (ex) {
      console.error('failed to update tag type:');
    }
  }



/**
 * load the dataset with the specified id
 * @param id 
 */
loadDataset = async (data: any) => {
  try {

    const id = data.id;
    const selectedEntry = data.entry || null;


    common.mode = 'PLATES';
    common.notify('ApplicationModeChanged');
    common.app.context.json = '';
    common.app.context.stitchInfoJson = '';

    common.plates.scanPolicy.loadingDataset = true;
    common.notify('PlatesClearDataset');
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const url = `${serverUrl}/dataset/${id}`;
    common.plates.scanPolicy.quitRequest();


    common.plates.filter.reset();
    common.plates.filter.canFilter = false;
    common.notify('PlatesFilterEnabled');

    axios.get(url, {headers: {'Authorization': common.loginToken}})
    .then ((response:any) => {
      var data = response.data;

      if (!data) {
        console.error('get dataset di not return data');
        return;
      }

      if (data.sequences?.length > 0)
        return axlesService.loadDataset(id, selectedEntry);

      const valid = common.validateImageIds(data.images);
      // wtt-138 countermeasures
      common.plates.ironIds = [...data.images];     
      const ids = data.images.join();
      common.plates.datasetHash = common.getHash(ids);
      common.addWarning(`dataset: ${id}, hash: ${common.plates.datasetHash}`);
      data.images = data.images.map((d:string, index:number) => ({
        _id: d, 
        index: index+1, 
        match: 0,
        status: "unvisited", state: {
          lastSaved: false, 
          problematic: false,
          bookmark: false}}))


      const alias = data.dataset.alias;
      this.updatSettings(alias);
      common.plates.records = data.images;
      common.plates.unfiltered = data.images;
      // initially do not filter, filter after first full scan!!
      common.plates.records = data.images;
      common.plates.datasetAlias = data?.dataset?.alias;
      common.plates.datasetId = id;
      this.restoreBookmarks();
      common.notify('PlatesDatasetLoaded');
      common.plates.scanPolicy.reset(); 
      common.plates.scanPolicy.loadingDataset = false;
      common.plates.settings.datasetId = `${common.mode} ${id}`; 
      common.notify('SaveSettings');

      // default to 1st image
      const entry = selectedEntry || data.images[0]?._id;
      common.notify('SelectPlateById', entry);
      
  
    })
    .catch((reason:any) => {
      common.relogIfTokenExpired(reason);
      console.error(`failed on get dataset: ${id}`);
      console.error(reason);
      common.plates.scanPolicy.loadingDataset = false;
    });
  } catch(ex) {
    common.relogIfTokenExpired(ex);
    console.error('failed to get datasets: ', ex);
  }
}
 delay = (n: number) => {
  return new Promise(function(resolve){
      setTimeout(resolve,n);
  });
}

/**
 * force scanning - forced flag used for end of scan notification
 */
forceScan = async () => {
  try {
    await common.plates.scanPolicy.asyncQuit();
    common.plates.scanPolicy.reset(true); 
    common.plates.scanPolicy.loadingDataset = false;
  } catch (ex) {
    console.error('failed to force scan:', ex);
  }
}

/**
 * select last saved record
 */
restoreBookmarks = () => {
  try {
    // load cached info
    this.loadState();
    const state = common.plates.state;
    if (state.datasetId !== common.plates.datasetId)
      return;

    const recs = common.plates.records;
    recs.forEach(r => {
      r.state.bookmark = state.bookmarks.includes(r._id);
      r.state.lastSaved = r._id === state.savedRecordId});
    common.notify('PlateStateUpdated');
  } catch (ex) {
    console.error('failed on goto last saved');
  }
}

/**
 * determines if the record contains pretags
 * @param annotations 
 * @returns 
 */
isPreTagged = (annotations: any[]): boolean => {
  try {
    if (!annotations)
      return false;

    const fields = ["PlateNumber","WhiteChars","Bilingual","PlateType","Nation","VehicleType",
    "ExtraType","Category","CharHeight","Poly","Rect","InternalPoly", "PlateType"];
    let preTagged = false;
    annotations.forEach(ant => {
        fields.forEach(f => {
          if(ant[f]?.status === 'pre-tagged')
            preTagged = true;
        });
      });
    return preTagged;
  } catch (ex) {
    console.error('failed on isPreTagged (2):', ex);
    return false;
  }
}


/**
 * returns the bccm data
 * @param nativeRecord 
 * @returns 
 */
getBccmFromNative = (nativeRecord: any) => {
  try {
    let bccm = nativeRecord?.image_library?.human?.bccm;
    if (!bccm)
      return null;

    if (!bccm.Annotations) {
      bccm.Annotations = [];
      return bccm;
    }

    if (!isArray(bccm.Annotations))
      bccm.Annotations = [bccm.Annotations];

    return bccm;
  } catch (ex) {
    console.error('failed to get bccm from native:', ex);
  }
}

/**
 * returns an array of vehicles from native record
 * @param nativeRecord 
 */
// getVehiclesFromNative = (nativeRecord: any) => {
//   try {
//     let plates = nativeRecord?.image_library?.human?.plates;
//     if (!plates)
//       return [];

//     const vehicles: VehicleBounding[] = [];
//     plates.vehicles?.forEach((v:any) => {
//       const vehicle = new VehicleBounding();
//       switch(v.pos) {
//         case 'F':
//         case 'front':
//           vehicle.pose = 'front';
//           break;

//         case 'R':
//         case 'rear':
//           vehicle.pose = 'rear';
//           break;

//         case 'full':
//           vehicle.pose = 'full';
//           break;
//       } 
//       vehicle.rect = v.rect;
//       vehicles.push(vehicle);
//     });
//     return vehicles;
//   } catch (ex) {
//     console.error('failed to get vehicles from native:', ex);
//     return [];
//   }

// }

getNativeVehicles = () => {
  try {
    const natives = [];
    common.plates.vehicles.forEach((v:VehicleBounding) => {
      const native:any = {};
      native.rect = v.rect;
      native.id_plates = v.plateIndices;

      natives.push(native);
    });

  } catch (ex) {
    console.error('failed to get native vehicles:', ex);
  }
}


/**
 * convert native record to app record 
 */
getPlatesFromNative = (nativeRecord: any) => {
  try {
    let plates = nativeRecord?.image_library?.human?.plates;
    if (!plates) {
      plates = {
        StateIndex:0,
        AnnotationState:1,
        AnnotationStatus:'',
        CorrectedPlate:null,
        VehicleType:null,
        CorrectedVehicleType:null,
        CorrectedBox:null,
        Lighting:3,
        Problematic:false,
        NeedHelp:false,
        ColoredImage:false,
        Annotations: []
      };
    }

    plates.Lighting = plates.Lighting || 3;
    plates.Problematic = plates.Problematic || false;
    plates.ColoredImage = plates.ColoredImage || false;

    // patch - array.Annotations is an object
    if (!plates.Annotations)
      plates.Annotations = [];
    else {
      if (!Array.isArray(plates.Annotations))
        plates.Annotations = [plates.Annotations];
    }

    // annotation patches come here
    plates.Annotations.forEach((a:any) => {

      // created and deleted before saving
      a.hoverSegIndex = -1;
      a.hoverIndex = -1;

      // WTT-371 - created late remove in case it was stored in db
      delete a.InternalSegments;
      

      // convert Extra to ExtraType
      if (a.Extra) {
        delete Object.assign(a, {['ExtraType']: a['Extra'] })['Extra'];
      }

      // novità - campo di regione -inizializza a campo vuoto
      if (!a.Region) {
        a.Region = { "status" : "tagged", value: ''};
      }

      if (!a.PlateNumber)
        a.PlateNumber = {"status" : "tagged", value: ''};

      // force an empty string for binding
      if (a.PlateNumber.value === null)
        a.PlateNumber.value = '';

      if (!a.PlateType)
        a.PlateType = {"status" : "tagged", value: 0};

      if (!a.VehicleType)
        a.VehicleType = {"status" : "tagged", value: '2'};

      if (!a.Nation)
        a.Nation = {"status" : "tagged", value: common.plates.settings.defaultNation};

      // if (!a.Nation.value)
      //   a.Nation.value = '';

      if (!common.plates.nations.includes(a.Nation?.value || '')) {
        a.Nation.value = '';
      }
      
     

      if (!a.Region)
        a.Region = {"status" : "tagged", value: common.plates.settings.region};

      if (!a.Region.value)
        a.Region.value = '';

      if (!a.InternalPoly) {
        a.InternalPoly = {"status": "tagged", value: [0,1,1,0]}
      }

      if (!a.InternalPoly.value)
        a.InternalPoly.value = [0,1,1,0];

      const nation = a.Nation.value;

      // ofer 21/06/2021 - request to remove trailing nation number
      a.Nation.value = a.Nation.value.split('-')[0];
      // patch
      // if (nation.startsWith('USA-') && nation !== 'USA-840') {
      //   a.Nation.value = 'USA';
      //   a.Region.value = nation.split('-')[1];
      // }

      if (!a.ExtraType)
        a.ExtraType = {"status" : "tagged", value: ''};

      if (!a.WhiteChars)
        a.WhiteChars = {"status" : "tagged", value:false};

      if (!a.Bilingual)
        a.Bilingual = {"status" : "tagged", value:false};

      if (!a.CharHeight)
        a.CharHeight = {"status" : "tagged", value:0};

      if (!a.Poly) {
        // WTT-265 poly is yellow in this case, avoid error
        //console.error('Annotation polygon is null !!');
        let r = this.getPlateRect(nativeRecord?.image_library?.header);
        if (!r) {
          const width = parseInt(nativeRecord?.image_library?.info?.width || '100');
          const height = parseInt(nativeRecord?.image_library?.info?.height || '100')
          const plateWidth = width / 10;
          const plateHeight = height / 10;
          r = [width/2 - plateWidth/2,height/2 - plateHeight/2, width/2 + plateWidth/2,height/2 + plateHeight/2];
        }
        a.polyPlaceholder = true;
        a.Poly = {"status" : "tagged", value: `${r[0]},${r[1]},${r[2]},${r[1]},${r[2]},${r[3]},${r[0]},${r[3]}`};
      }


      if (!a.InternalPoly)
        a.InternalPoly = {"status" : "tagged", value:[0,1,1,0]};

      if (!a.Chars) 
        a.Chars = {"status": "tagged", value: []};

      // patch nations 051, - 057
      if (a.Nation?.value?.includes('-ARE-')) {
        a.Region.value = a.Nation.value.split('-')[2];
        a.Nation.value = "ARE";
      }

      // this property isn't used (simplified mode)
      if (a.Rect) {
        a.Rect.status = "tagged";
      }

      // WTT-76
      if (a.PlateTextTranslated) {
        a.PlateNumber.value = a.PlateTextTranslated.value;
      }

      // retro compatibilità -casi eccezionali
      if (a.Nation?.value === 'EGY' && !a.PlateTextTranslated) {
        a.PlateTextTranslated = {
          value: a.PlateNumber?.value,
          status: 'tagged'
        }
      }

      a.Accepted = false;
    });

    return plates;
  } catch (ex) {
    console.error('failed to getRecordFromNative')
  }
}

/**
 * returns plate rect from header info
 * @param header 
 * @returns 
 */
getPlateRect = (header: any) => {
  try {
    if (!header)
      return null;
      
    const keys = ['I_PLATE_MIN_X','I_PLATE_MIN_Y','I_PLATE_MAX_X','I_PLATE_MAX_Y'];
    return keys.map(k => parseInt(header[k]));
  } catch (ex) {
    console.error('failed to get plateRect:',ex);
    return null;
  }
}

/**
 * returns lane coordinates fro header info
 * @param metadata 
 * @returns 
 */
getLanes = (metadata: any): any => {
  try {
    const entry = metadata?.S_OCR_CFG;
    if (!entry)
      return null;

    const tokens = entry.split(' ');
    let lane1 = tokens.find((t:string) => t.includes('TL_TR_BL_BR1'));
    let lane2 = tokens.find((t:string) => t.includes('TL_TR_BL_BR2'));
    let delimiters = tokens.find((t:string) => t.includes('UD'));
    lane1 = lane1?.split('(')[1]?.split(')')[0];
    lane2 = lane2?.split('(')[1]?.split(')')[0];
    lane1 = lane1?.split(',').map((x:string) => parseInt(x));
    lane2 = lane2?.split(',').map((x:string) => parseInt(x));
    delimiters = delimiters?.split('(')[1]?.split(')')[0];
    delimiters = delimiters?.split(',').map((x:string) => parseInt(x));
    return {lanes: [lane1, lane2], delimiters };
  } catch (ex) {
    console.error('failed to get lanes:', ex);
    return null;
  }
}


/**
 * user clicked cancel - check and warn in case of pending changes
 * @param id 
 * @returns 
 */
handleCancelChanges = async(id: string) => {
  try {

    // ofer 06/04/2023 - -suspend until works with egy
    const changes = this.platePendingChanges();
    if (changes) {
      var confirmed = await common.confirm("Cancel changes", 
      `Plate record changed, are you sure you want to discard changes ?`);
      if (confirmed !== true) {
        return;
      }
    }

    this.getRecord2(id);

  } catch (ex) {
    console.error('failed to handle cancel changes:', ex);
  }
}

handleSelectionChanged = async (id:string) => {
  try {
    if (common.plates.handlingSelectionChanged)
      return;

    common.plates.handlingSelectionChanged = true;

    common.plates.changeConfirmation = undefined;
    // ofer 06/04/2023 - suspend until works with egy
    const changes = this.platePendingChanges();
    if (changes) {
      var confirmed = await common.confirm("Cancel changes", 
      `Plate record changed, are you sure you want to discard changes ?`);
     common.plates.changeConfirmation = confirmed as boolean;
      if (confirmed !== true) {
        const previousId = common.plates.previousSelectionId;
        this.skipChangesCheck(true);
        common.notify('PlatesRevertSelection', previousId  as any);
        return;
      }
    }
    
    // only when reverting to previous records - do not get records
    if (!common.plates.reverting) {
      this.getRecord2(id);
    }

  } catch (ex) {
    console.error('failed to handle selection changed', ex);
  } finally {
    common.plates.handlingSelectionChanged = false;
  }
}

/**
 * get record with the specified id from current dataset
 * @param id 
 */
getRecord2 = async (id: string) => {
  try {
  if (common.plates.getttingRecord)
    return;

    
  common.plates.getttingRecord = true;
  await common.assureLogin();
  if (!id) throw(new Error('no Id to getRecord'));
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const url = `${serverUrl}/items/${id}`;

    common.unselectAnnotations();
    // clean current data
    common.plates.selectedRecord = null;
    common.plates.headerPlateNumber = '';
    common.plates.vehicles = [];
    common.plates.lights = [];
    common.plates.editedChars = [];
    common.notify('AnnotationSelected');

    const response = await axios.get(url, {headers: {'Authorization': common.loginToken}});
    //.then((response:any)=> {

      // ofer WTT-204 - before making any channges:
      // cache to visualizze and detect changes
      common.app.context.json = JSON.stringify(response.data, null, 2);
      common.app.context.id = id;
      
      // TODO - NOT USED - REMOVE
      common.plates.nativeRecord = response.data;

      // transform from native to app
      common.plates.selectedRecord = this.getPlatesFromNative(response.data);
      common.plates.selectedRecord.bccm = this.getBccmFromNative(response.data);
      common.plates.vehicles = platesVehicles.getVehiclesFromNative(response.data);
      common.plates.insides = insideInspection.getInsidesFromNative(response.data);
      common.plates.lights = lightsService.getLightsFromNative(response.data);
      common.plates.hazards = hazardService.getHazardsFromNative(response.data);
      common.plates.reflectives = reflectiveService.getReflectivesFromNative(response.data);
   
   
      // WTT-241 remove invalid hidden unicodes
      let headerPlateNumber = response.data?.image_library?.header?.S_PLATE || '';
      const invalidChars = ['\u200C', '\u202C', '\u202D', '\u202E'];
      invalidChars.forEach(ch => {
        let pos = headerPlateNumber.indexOf(ch);
        headerPlateNumber = headerPlateNumber.replaceAll(ch, '');
        if (headerPlateNumber.indexOf(ch) > -1)
          console.error('failed to remove chars');
      })
      common.plates.headerPlateNumber =headerPlateNumber;
    

   
      if (!common.app.search.open) {
        common.plates.settings.datasetSelectedItemId = id;
        common.notify('SaveSettings');
      }


      // call after plates and bccms are set
      // Ofer 18/10/2021 - DATA CAN BE MODIFIED BY USER - READ BY getVehiclesFromNative()
      // platesVehicles.updateVehicle();

      common.plates.bccmMode = common.plates.selectedRecord.bccm ? true : false;
      // hack for testing
      platesService.canvas.tags = common.plates.selectedRecord.Annotations;
      const ants = common.plates.selectedRecord.Annotations;

      // WTT-417
      this.selectFirstAnnotation();

      const lanes = this.getLanes(response.data.image_library.header);
      platesService.canvas.setLanes(lanes);

      const plateRect = this.getPlateRect(response.data.image_library.header);
      platesService.canvas.setPlateRect(plateRect);
      common.notify('PlatesRecordLoaded');
      this.canvas.pendingPaint = true;
      this.validateAnnotations();

    // 04/02/2022 WTT-215
    const token =  common.loginToken;
    const imageUrl = `${serverUrl}/items/${id}?${common.imageToken}&raw`;
    this.canvas.setImage(imageUrl);

  } catch (ex) {
    common.relogIfTokenExpired(ex);
    console.error('failed to getPlateRecord:', ex);
  } finally {
    common.plates.getttingRecord = false;
  }
}

/**
 * Select first annotation matching newTagType
 * WTT-417
 */
selectFirstAnnotation() {
  try {
    const plates = common.plates;
    common.unselectAnnotations();
    const ants = plates.selectedRecord.Annotations || [];
    const insides = plates.insides || [];
    const vehicles = plates.vehicles || [];
    const lights = plates.lights || [];
    const hazards = plates.hazards || [];
    const reflectives = plates.reflectives || [];
    const tagType = plates.newTagType;
    switch(tagType) {
      case 'Ocr': if (ants.length > 0) plates.selectedAnnotation = ants[0]; break;
      case 'Chars': if (ants.length > 0) plates.selectedAnnotation = ants[0]; break;
      case 'Inside': if (insides.length > 0) plates.selectedInside = insides[0]; break;
      case 'Vehicle': if (vehicles.length > 0) plates.selectedVehicle = vehicles[0]; break;
      case 'Lights': if (lights.length > 0) plates.selectedLights = lights[0]; break;
      case 'Hazard': if (hazards.length > 0) plates.selectedHazard = hazards[0]; break;
      case 'Reflective': if (reflectives.length > 0) plates.selectedReflective = reflectives[0]; break;
    }
    common.notify('AnnotationSelected');
  } catch (ex) {
    console.error('failed to select first annotation:', ex);
  }
}

/**
 * move selected annotation to front
 */
promoteSelectedAnnotation() {
  try {
    const plates = common.plates;
    const ants = plates.selectedRecord.Annotations || [];    const vehicles = plates.vehicles || [];
    const hazards = plates.hazards || [];
    const reflectives = plates.reflectives || [];
    const tagType = plates.newTagType;
    switch(tagType) {
      case 'Ocr': this.moveToHead(ants, plates.selectedAnnotation); break;
      case 'Vehicle': this.moveToHead(vehicles, plates.selectedVehicle); break;
      case 'Hazard': this.moveToHead(hazards, plates.selectedHazard); break;
      case 'Reflective': this.moveToHead(reflectives, plates.selectedReflective); break;
    }
    common.notify('AnnotationSelected');
  } catch (ex) {
    console.error('failed to select first annotation:', ex);
  }
}

/**
 * move specified item of array to front
 * @param a 
 * @param selected 
 */
moveToHead(a:any[], selected:any) {
  try {
    a.sort(function(x,y){ return x == selected ? -1 : y == selected ? 1 : 0; });
  } catch (ex) {
    console.error('failed to move to head:', ex);
  }
}




getAnnotationArray(x:any) {
  try {
    if (Array.isArray(x)) return x;
    return x?.Annotations || [];
  } catch (ex) {
    return [];
  }
}

deepEqual(x:any, y:any):boolean {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => this.deepEqual(x[key], y[key]))
  ) : (x === y);
}

/**
 * helper for compare values with diff
 * @param x 
 * @param y 
 * @param diff 
 * @param key 
 * @returns 
 */
compareDiff(x:any, y:any, diff:any[], key:any):boolean {
  if (x !== y) {
    diff.push( `${key} ${y} => ${x}`);
  }
  return (x === y);
}

/**
 * helper for compare keys with diff
 * @param kx 
 * @param ky 
 * @param diff 
 * @returns 
 */
compareKeys(kx:string[], ky: string[], diff: any[]): boolean {
  if (kx.length !== ky.length) {
    let difference = kx
                 .filter(x => !ky.includes(x))
                 .concat(ky.filter(x => !kx.includes(x)));
    diff.push(difference);
  }
  return kx.length === ky.length;
}

/**
 * true if equal, diff contains a list of changes (short circuit logic, probably 1 change)
 * @param x 
 * @param y 
 * @param diff 
 * @param k 
 * @returns 
 */
deepDiff(x:any, y:any, diff:any[], k:any = ''):boolean {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    this.compareKeys(ok(x), ok(y), diff) &&
      ok(x).every(key => this.deepDiff(x[key], y[key], diff, k + '.' + key))
  ) : this.compareDiff(x, y, diff, k);
}




/**
 * Detect record pending changes
 */
/**
 * Detect record pending changes
 */
platePendingChanges(): string {
  try {
    if (common.plates.skipChangesCheck)
      return '';

    const nativeRecord = common.plates.nativeRecord;
    if (!nativeRecord)
      return '';

    if (!common.app.context.json)
      return '';

    // e stato salvato durante la lettura
    const original = JSON.parse(common.app.context.json);

    // WTT-371 - promote original record to compare
    let nativePlates = this.getPlatesFromNative(original);
    // exception - remote placeholder marker for tags without poly
    nativePlates.Annotations.forEach((a:any) => delete a.polyPlaceholder);
    // plates ready to be saved - THIS IS CLONED
    
    const plateDiffs:string[] = [];
    const currentPlates = this.getNativePlates();
    // hack - verify bccm separately
    delete currentPlates.bccm;
    delete nativePlates.bccm;

    const wrapped = {image_library: {human: {plates:  currentPlates}}};
    const promoted = this.getPlatesFromNative(wrapped);
    const platesChanged = !this.deepDiff(promoted, nativePlates, plateDiffs);
    
    // compare vehicle tagging
    const vehicleDiffs: string[] = [];
    const nativeVehicles = platesVehicles.getVehiclesFromNative(original); 
    const pendingVehicles =  platesVehicles.getNativeVehicles(common.plates.vehicles);
    const wrappedVehicles = {image_library: {human: {vehicle: pendingVehicles}}}
    const promotedVehicles = platesVehicles.getVehiclesFromNative(wrappedVehicles);
    const vehiclesChanged = !this.deepDiff(promotedVehicles, nativeVehicles,vehicleDiffs);

    // compare inside inspection
    const insideDiffs: string[] = [];
    const nativeInsides = insideInspection.getInsidesFromNative(original);
    const pendingInsides =  insideInspection.getNativeInsides(common.plates.insides);
    const wrappedInsides = {image_library:{human:{inside_inspection: {Annotations:pendingInsides}}}};
    const promotedInsides = insideInspection.getInsidesFromNative(wrappedInsides);
    const insideChanged = !this.deepDiff(promotedInsides, nativeInsides, insideDiffs);


    let {previous, current} = lightsService.getOriginalAndCurrent(original);
    const lightDiffs: string[] = [];
    const lightChanged = !this.deepDiff(previous,current, lightDiffs);
  
    // WTT-414
    const hazardDiffs: string[] = [];
    let hazardChanged = false;
    {
      const {previous, current} = hazardService.getOriginalAndCurrent(original);
      hazardChanged = !this.deepDiff(previous,current, hazardDiffs)
    }

    const reflectiveDiffs: string[] = [];
    let reflectiveChanged = false;
    {
      const {previous, current} = reflectiveService.getOriginalAndCurrent(original);
      reflectiveChanged = !this.deepDiff(previous,current, reflectiveDiffs)
    }
    

    let changes = '';
    if (platesChanged) changes += `plates tagging changed: ${plateDiffs.toString()}`;
    if (vehiclesChanged) changes += `Vehicle tagging changed: ${vehicleDiffs.toString()}`;
    if (insideChanged) changes += `Inside inspection changed: ${insideDiffs.toString()}`;
    if (lightChanged) changes += `Traffic lights changed: ${lightDiffs.toString()}`;
    if (hazardChanged) changes += `Hazards changed: ${hazardDiffs.toString()}`;
    if (reflectiveChanged) changes += `Reflectives changed: ${reflectiveDiffs.toString()}`;
    

    return changes;
  } catch (ex) {
    console.error('Failed on plate record changed:', ex);
    return '';
  }
}

/**
 * detect plates in global changes
 * @param before 
 * @param after 
 * @returns 
 */
getPlateGlobalChanges(before: any, after: any) {
  try {
      // determine if globals have default values
      const isDefault = after.Lighting == 3 && after.ColoredImage == false && after.Problematic == false && after.NeedHelp == false;
      // if no globals before and values are default - user has not modified anything 
      if (!before && isDefault)
        return [];

      // otherwise detect changes
      const diffs:string[] = [];
      const plateGlobals = ['Lighting', 'Problematic', 'NeedHelp', 'ColoredImage'];
    var plateGlobalsChanged = false;
    plateGlobals.forEach(f => {
      // ofer 12/04/2023
      // 18/04/2023 WTT-371
      const globalAttrib = before ? before[f] : undefined;
      if (globalAttrib !== undefined && globalAttrib !== after[f]) {
        plateGlobalsChanged = true;
        diffs.push(f);
      }
    });
    return diffs;
  } catch (ex) {
    console.error('failed to get plates global changes:', ex);
    return [];
  }
}

getNativePlates() {
  try {
    const rec = common.plates.selectedRecord;
    const cloned = JSON.parse(JSON.stringify(rec));
    // tweaks - remove internal properties which are not part of native record
    const ants = cloned.Annotations;
    ants.forEach((ant:any) => {
      delete ant.Accepted;
      delete ant.hoverIndex;
      delete ant.hoverSegIndex;
      delete ant.polyPlaceholder;
      delete ant.InternalSegments;
    });

    // WTT-76 - egy is stored in arabic 
    ants.forEach((ant:any) => {
        const nation = ant?.Nation?.value;
        if (nation === 'EGY') {
          const latin = ant.PlateNumber.value;
          // WTT-76
          const arabic = arabicConverter.latinToEgyptian(latin);
          ant.PlateNumber.value = arabic;
          ant.PlateTextTranslated = {
            value: latin,
            status: 'tagged'
          }
        }
      })
    
    return cloned;
  } catch (ex) {
    console.error('failed to get native plates:', ex);
  }
}

getCharMap = (nation:string) => {
  try {
    const plates = common.plates;
    switch(nation) {
      case 'SAU': return plates.latinToSau;
      case 'EGY': return plates.latinToArabic;
      case 'IRQ': return plates.latinToArabic;
      case 'IRN': return plates.latinToIranian;
      case 'MAR': return plates.latinToArabic;
    }
    return [];
  } catch(ex) {
    console.error('Failed to get charset:', ex);
  }
}

/**
 * convert from latin to arab
 * @param latin 
 * @param nation 
 * @returns 
 */
latinToArab = (latin:string, nation:string) => {
  try {
    const chars = this.getCharMap(nation) as any[];
    // if no conversion return original string
    if (!chars)
      return latin;

    var native = "";
    for (let i = 0; i < latin.length; i++) {
      const ch = latin.charAt(i)?.toLocaleLowerCase() as string;
      var entry = chars.find(c => c.latin === ch);
      if (entry)
        native += entry.arabic;
      else
        native += '?';
     }
     return native;
  } catch(ex) {
    console.error("Failed on lation to arab");
    return latin;
  }
}




////////////////////////////////////////////////////////////////////////////////////////

/**
 * convert from arab to latin
 * @param arab 
 * @param nation 
 * @returns 
 */
arabToLatin = (arab:string, nation:string) => {
  try {

    const chars = this.getCharMap(nation) as any[];
    // no conversion - return original string
    if (!chars)
      return arab;

    var latin = "";
    for (let i = 0; i < arab.length; i++) {
      const ch = arab.charAt(i) as string;
      var entry = chars.find(c => c.arabic === ch);
      if (entry)
        latin += entry.latin.toUpperCase();
      else
        // WTT-76 - preserve unsupported characters
        latin += ch;
     }
     return latin;

  } catch(ex) {
    console.error('failed on arab to latin', ex);
    return arab;
  }
}

/**
 * add non-joiner
 * @param arab 
 */
addSpacers = (arab:string) => {
  try {
    const chars = arab.split('');
    let spaced = chars.join('\u200C');
    spaced = '\u202D' + spaced;
    return spaced;
  } catch(ex) {
    console.error('failed to add spacers:', ex);
  }
}

/**
 * remove non-joiners
 * @param arab 
 * @returns 
 */
removeSpacers = (arab:string) => {
  try {
    let dense = arab.replaceAll('\u200C', '');
    dense = dense.replace('\u202D', '');
    return dense;
  } catch(ex) {
    console.error('failed to add spacers:', ex);
  }
}


/**
 * save the current record
 * @returns 
 */
saveRecord2 = async () => {
  try {

    // prep 
    const rec = common.plates.selectedRecord;
    if (!rec)
      return;

    if (common.plates.saving)
      return;

    common.plates.saving = true;
    await common.assureLogin();


    // tweaks - remove internal properties
    const ants = rec.Annotations;
    ants.forEach((ant:any) => {
      delete ant.Accepted;
      delete ant.hoverIndex;
      delete ant.hoverSegIndex;
      delete ant.polyPlaceholder;
      delete ant.InternalSegments;
    });

    const id = common.plates.nativeRecord._id;
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const url = `${serverUrl}/items/${id}`;
    const nativeRecord = common.plates.nativeRecord;
    if (!nativeRecord.image_library.human)
      nativeRecord.image_library.human = {};
    
    nativeRecord.image_library.human.plates = rec;

    // WTT-76 - store arab, not latin for EGY
    const nativeAnts = nativeRecord.image_library.human.plates.Annotations;
    if (nativeAnts) {
      nativeAnts.forEach((ant:any) => {
        const nation = ant?.Nation?.value;
        if (nation === 'EGY') {
          const latin = ant.PlateNumber.value;
          // WTT-76
          const arabic = arabicConverter.latinToEgyptian(latin);
          ant.PlateNumber.value = arabic;
          ant.PlateTextTranslated = {
            value: latin,
            status: 'tagged'
          }
        }
      })
    }


    // WTT-143 - save vehicles under human
     // rec.vehicles = platesVehicles.getNativeVehicles();
     
    nativeRecord.image_library.human.vehicle = platesVehicles.getNativeVehicles(common.plates.vehicles);
    
  

    // verify that legacy structures are eliminates
    if (nativeRecord.image_library.human.plates.vehicle !== undefined)
      delete nativeRecord.image_library.human.plates.vehicle;

      if (nativeRecord.image_library.human.plates.vehicles !== undefined)
      delete nativeRecord.image_library.human.plates.vehicles;
      
    // verify that legacy structures are eliminates
    if (nativeRecord.image_library.human.plates.bccm !== undefined)
      delete nativeRecord.image_library.human.plates.bccm;

      // WTT-311 - verify inside annotations path
    if (!nativeRecord.image_library.human.inside_inspection)
      nativeRecord.image_library.human.inside_inspection = {};

    // set inside_inspections
    nativeRecord.image_library.human.inside_inspection.Annotations = insideInspection.getNativeInsides(common.plates.insides);


    if (!nativeRecord.image_library.human.trafficlight)
      nativeRecord.image_library.human.trafficlight = {};

    nativeRecord.image_library.human.trafficlight.Annotations = lightsService.getNativeLights(common.plates.lights);

    // WTT-414
    if (!nativeRecord.image_library.human.hazardous)
      nativeRecord.image_library.human.hazardous = {};
    nativeRecord.image_library.human.hazardous.Annotations = hazardService.getNativeHazards(common.plates.hazards);

    // WTT-429
    if (!nativeRecord.image_library.human.reflective_bar)
      nativeRecord.image_library.human.reflective_bar = {};
    nativeRecord.image_library.human.reflective_bar.Annotations = reflectiveService.getNativeReflectives(common.plates.reflectives);

    let dumped = JSON.stringify(nativeRecord.image_library.human, null, 2);
    console.log(dumped);

    const reply = await axios.put(url, nativeRecord, {headers: {'Authorization': common.loginToken}});
    common.plates.state.savedRecordId = id;
    common.plates.state.datasetId = common.plates.datasetId;
    this.saveState();
    this.restoreBookmarks();
    // WTT-363 - skip checks
    this.skipChangesCheck();
    common.notify2('GotoNextRecord');

  } catch (ex) {
    common.relogIfTokenExpired(ex);
    console.error('failed to save record: ', ex);
  } finally {
    common.plates.saving = false;
  }
}

skipChangesCheck = (reverting: boolean = false) => {
  try {
    common.plates.skipChangesCheck = true;
    common.plates.reverting = reverting;
    setTimeout(() => {
      common.plates.skipChangesCheck = false;
      common.plates.reverting = false;  
    }
    , 200);
  } catch (ex) {
    console.error('failed on skip changes check:', ex);
  }
}

  /**
   * deprecated
   * @param path 
   */
  generatePlatesfile = (path: string) => {
    try {
      const max = 1000;
      path = encodeURIComponent(path);
      axios.post(`${common.rest}/plates/${path}?max=${max}`);

    } catch (ex) {
      console.error('failed to start plates generation:', ex)
    }
  }

  /**
   * save the specified record
   * @param data 
   */
  saveRecord(path: string, data: any):Promise<any> {
    try {
        path = encodeURIComponent(path);
         const index = data.id;
          return axios.post(`${common.rest}/plates/${path}/${index}`, data, {headers: {'Authorization': common.loginToken}})
          .then( (res:any) => {
            console.log(`statusCode: ${res.statusCode}`)
          })
          .catch( (error:any) => {
            common.relogIfTokenExpired(error);
            console.error(`failed on save record: ${path}`);
            console.error(error)
          }) 
    } catch (exception) {
      common.relogIfTokenExpired(exception);
      console.error('failed to save current record: ', exception);
      return Promise.reject("failed to get dataset record");
    }
  }

  /**
   * get the specified record
   * @param datasetIndex 
   * @param recordId 
   */
  // getRecord = (path: string, recordId: string) => {
  //   try {
  //     path = encodeURIComponent(path);

  //     // test - fetch directly from imagelib
  //     const rest = common.rest;
  //     const imageLibUrl = rest.replace('3866', '3888');
  //     if (common.app.datasetItemsFromImagelib)
  //       return fetch(`${imageLibUrl}/plates/${path}/${recordId}`);


  //     return fetch(`${common.rest}/plates/${path}/${recordId}`);
  //   } catch (e) {
  //     console.error('failed to get datasets: ')
  //     return Promise.reject("failed to get dataset record");
  //   }
  // };

  /**
   * records filter - deprecated
   * @param p 
   * @returns 
   */
  passFilter = (p: any) => {
    try {
      const filter = common.plates.filter;
      const bccm = p.status.includes('+bccm');
      const vehicles = p.status.includes('+vehicles');
      const insides = p.status.includes('+insides');
      const lights = p.status.includes('+lights');
      const hazards = p.status.includes('+hazards');
      const reflectives = p.status.includes('+reflectives');
      const status = p.status.split('+')[0];

      if (filter.problematic === false &&  p.state.problematic)
        return false;

      if (filter.problematic === true && !p.state.problematic)
        return false;

      if (filter.tagged === false && p.tagged)
        return false;

      if (filter.tagged === true && !p.tagged)
        return false;

      if (filter.marked === false && p.state.bookmark)
        return false;

      if (filter.marked === true && !p.state.bookmark)
        return false;

      if (filter.bccm == false && bccm)
        return false;

      if (filter.bccm === true && !bccm)
        return false;

      if (filter.vehicles === false && vehicles)
        return false;

      if (filter.vehicles === true && !vehicles)
        return false;

      if (filter.insides === false && insides)
        return false;

      if (filter.insides === true && !insides)
        return false;

      if (filter.lights === true && !lights)
        return false;

      if (filter.lights === false && lights)
        return false;

      if (filter.hazards === true && !hazards)
        return false;

      if (filter.hazards === false && hazards)
        return false;

      if (filter.reflectives == true && !reflectives)
        return false;

      if (filter.reflectives == false && reflectives)
        return false;

      if (filter.pretagged === false && p.Pretagged)
        return false;

      if (filter.pretagged === true && !p.Pretagged)
        return false;

      if (filter.match === true && p.match !== PlateMatch.Match)
        return false;

      if (filter.match === false && p.match !== PlateMatch.NoMatch)
        return false;

      if (filter.occluded === true && !p.occluded)
        return false;

      if (filter.occluded === false && p.occluded)
        return false;

      if (filter.chars == true && !p.chars)
        return false;

      if (filter.chars === false && p.chars)
        return false;
     
      // vehicle type
      if (!this.passVehicleType(p))
        return false;

      // if (!this.passExtraValue(p))
      //   return false;

    if (filter.search) {
      if (!this.plateNumbersInclude(p.plateNumbers, filter.search))
        return false;
    }

      if (filter.searchId) {
        if (!p._id?.includes(filter.searchId))
        return false;
      }

      return true;
    } catch (ex) {
      console.error('failed on pass filter:', ex);
      return false;
    }
  }

  plateNumbersInclude(plateNumbers: string[], search: string) {
    try {
      for (let i = 0; i < plateNumbers.length; i++)
        if (plateNumbers[i]?.includes(search))
          return true;

      return false;
    } catch(ex) {
      console.error('failed on plateNumbersInclude:', ex);
    }
  }

  /**
   * whether the specified record passes vehicle type filter
   * @param p - record to examine
   * @returns 
   */
  passVehicleType = (p:any):boolean => {
    try {
      const vt = common.plates.filter.vehicleType;
      if (vt === -1)
        return true;

      if (!p.vehicleTypes)
        return false;

      for (let i = 0; i < p.vehicleTypes.length; i++)
        if (p.vehicleTypes[i] === vt)
          return true;
      
      return false;
    } catch (ex) {
      console.error('failed on passVehicleType:', ex);
      return false;
    }
  }

  passExtraValue = (p:any):boolean => {
    try {
      const vt = common.plates.filter.extraType;
      if (vt === -1)
        return true;

      if (!p.extraTypes)
        return false;

      for (let i = 0; i < p.extraTypes.length; i++)
        if (p.extraTypes[i] === vt)
          return true;
      
      return false;
    } catch (ex) {
      console.error('failed on passVehicleType:', ex);
      return false;
    }
  }


  /**
   * update plates filter
   */
  updatePlatesFilter = () => {
    try {
      const plates = common.plates;
      plates.records = plates?.unfiltered?.filter(p => this.passFilter(p));
      this.sortRecords(plates.records);
      common.notify('PlateListChanged');

    } catch (ex) {
      console.error('failed to update plates filter: ', ex);
    }
  }

  /**
   * sort records
   * @param recs 
   * @returns 
   */
  sortRecords = async (recs:any[]) => {
    try {
      const settings = common.plates.settings;
      if (!settings.orderBy)
        return;

      if (!common.app.orderApply)
        return;

      const f0 = settings.orderField0;
      const f1 = settings.orderField1;

      if (!f0)
        return;

      // used for checking if ordering was effective
      common.app.orderValidField0 = false;
      common.app.orderVAlidField1 = false;

      // sort records
      // udefined at the end of the list !!!!
      recs.sort((a:any,b:any) => {
        let a0 = a.order[0];
        let b0 = b.order[0];
        let a1 = a.order[1];
        let b1 = b.order[1];
        const c0 = this.compareSingleField(a0, b0, f0);
        const c1 = this.compareSingleField(a1, b1, f1);

        if (c0)
          common.app.orderValidField0 = true;

        if (c1)
          common.app.orderVAlidField1 = true;

        return c0 * 2 + c1;
      });

      // conditions for launching alert on uneffective fields
      const invalid0 = settings.orderField0 && !common.app.orderValidField0;
      const invalid1 = settings.orderField1 && !common.app.orderVAlidField1;
      if (invalid0 || invalid1) {
          let msg = '';
          if (invalid0)
              msg += `${settings.orderField0} `;
          if (invalid1)
              msg += `${settings.orderField1} `;
          
          await common.alert("Plate list order", `Order did not change plate list, please check ${msg}`);
      }

    } catch (ex) {
      console.error('failed to sort records');
    }
  }

  /**
   * compare a single field
   * @param a0 
   * @param b0 
   * @param f0 
   * @returns 
   */
  compareSingleField = (a0:any, b0:any, f0:string):number => {
    try {
      if (a0 === undefined && b0 === undefined) return 0;
      if (a0 === undefined) return 1;
      if (b0 === undefined) return -1;
      a0 = this.getSortKey(a0, f0) as string;
      b0 = this.getSortKey(b0, f0) as string;
      if (a0 === undefined && b0 === undefined) return 0;
      if (a0 === undefined) return 1;
      if (b0 === undefined) return -1;
      return a0.localeCompare(b0);
    } catch (ex) {
      console.error('failed to compare single field:', ex);
      return 0;
    }
  }



  getSortKey = (v:any, field:string): string => {
    try {
      switch(field) {
        case 'image_library.header.S_DATE':
          const milli = (new Date(v)).getTime();
          return milli.toString();
      }
      return v.toString();
    } catch (ex) {
      console.error('failed to get sort key:', ex);
      return '';
    }
  }

  /**
   * detects wether the returned get data stems from redirection to index.html
   * @param data 
   * @returns 
   */
  redirectionError = (data: any) => {
    try {
      if (!data)
        return false;

      if (typeof data !== 'string')
        return false;

        // redicrected to index.html
       return data?.startsWith('<!doctype html>');
      
    } catch (ex) {
      console.error('failed on redirectionError:', ex);
    }
  }

  /**
   * returns the string associated with the specified url
   */
  getStaticResource =  (url: string): Promise<any> => {
    try {
      const p = new Promise((resolve,reject) => {
        axios.get(url, {headers: {'Authorization': common.loginToken}})
        .then((reply:any) => {
          const redirected =  typeof reply.data === 'string' && reply.data.startsWith('<!doctype html>');  
          if (redirected) console.error(`${url} not found`);
          resolve(redirected ? null : reply.data);
        })
        .catch((reason:any) => {
          reject("failed to get resource: " + reason);
        });

      });
     
      return p
    } catch (ex) {
      console.error('failed to get static resource', ex);
      return Promise.reject('failed to get static resoure');
    }
  }

  getFilenameWithoutExtention = (path: string) => {
    try {
      var file = path.substring(path.lastIndexOf('/')+1);
	    return file.split('.')[0];
    } catch (ex) {
      console.error('failed to get filename without extention:', ex);
    }
  }

  /**
   * load resources from data folder
   */
  loadStaticResources = async() => {
    try {
      const plates = common.plates;

      const nations =  await this.getStaticResource('data/nations.txt');
      if (nations) {
        plates.nations = nations.match(/[^\r\n]+/g);
        // ofer 21/06/2021
        plates.nations = plates.nations.map(n => n.split('-')[0]);
      }

      const arabic =  await this.getStaticResource('data/arabic-nations.txt');
      if (arabic)
        plates.arabicNations = arabic.match(/[^\r\n]+/g);

      

      const plateFaces = await axios.get('data/plate-faces.json');
      plates.plateFaces = plateFaces.data;

      const arabicChars = await axios.get('data/arabic-chars.json');
      const arabicToLatin = arabicChars.data;
      plates.latinToArabic = [];  
      for( const arabic in arabicToLatin) {
        if (arabicToLatin.hasOwnProperty(arabic)) {
          const latin = arabicToLatin[arabic]; 
          plates.latinToArabic.push({latin, arabic })
        }
      } 

      const iranianChars = await axios.get('data/iranian-chars.json');
      const iranianToLatin = iranianChars.data;
      plates.latinToIranian = [];  
      for( const irn in iranianToLatin) {
        if (iranianToLatin.hasOwnProperty(irn)) {
          const latin = iranianToLatin[irn]; 
          plates.latinToIranian.push({latin, arabic: irn })
        }
      } 

      const sauChars = await axios.get('data/sau-chars.json');
      const sauToLatin = sauChars.data;
      plates.latinToSau = [];  
      for( const sau in sauToLatin) {
        if (sauToLatin.hasOwnProperty(sau)) {
          const latin = sauToLatin[sau]; 
          plates.latinToSau.push({latin, arabic: sau })
        }
      } 

      plates.latinToOman =  await this.getChars('data/oman-chars.json');
  
      // WTT-398 - usa states - 57 + 1 = 58
      // https://www.faa.gov/air_traffic/publications/atpubs/cnt_html/appendix_a.html
      const regions = await axios.get('data/regions.json');
      if (regions.data && Array.isArray(regions.data))
        plates.regions = regions.data;
     
      let data = await this.getStaticResource('data/extra-types.txt');
      if (data) {
      data = data.match(/[^\r\n]+/g);
      data = data.map((ex:string) => ex.split('\t'));
      plates.extraTypes = data;

     }

    const vehicleTypes = await axios.get('data/vehicle-types.json');
    if (vehicleTypes.data && Array.isArray(vehicleTypes.data)) {
      plates.vehicleTypes = vehicleTypes.data;
      // statistics
      // WTT-403 - code field and sort
      plates.stats.vehiclesPie =  plates.vehicleTypes.map(vt => ({name:vt.name, title: vt.name, code:vt.value, value:0, percentage: 0, color:vt.color}));
      plates.stats.vehiclesPie.sort((a,b) => a.name.localeCompare(b.name));
      // filter
      plates.filter.vehicleTypes = plates.vehicleTypes.map(vt => ({name: vt.name, value:vt.value, count: 0}));
      plates.filter.vehicleTypes.unshift({name: 'All', value:-1, count: 0});
    }

    const plateTypes = await axios.get('data/plate-types.json');
    if (plateTypes.data && Array.isArray(plateTypes.data)) {
      plates.plateTypes = plateTypes.data;
      // statistics
      // WTT-403 - introduce code and sort
      plates.stats.platesPie =  plates.plateTypes.map((vt:any) => ({name:vt.name, title: vt.name, code:vt.value, value:0, percentage: 0, color:vt.color}));
      plates.stats.platesPie.sort((a,b) => a.name.localeCompare(b.name));
      // filter
      plates.filter.plateTypes = plates.plateTypes.map(vt => ({name: vt.name, value:vt.value, count: 0}));
      plates.filter.plateTypes.unshift({name: 'All', value:-1, count: 0});

        // WTT-354 - DOPO platesPie !!
        plates.plateTypes.push({value: 100, name:'Speed 100'});
        plates.plateTypes.push({value: 80, name:'Speed 80'});
        plates.plateTypes.push({value: 70, name:'Speed 70'});
        plates.plateTypes.push({value: 60, name:'Speed 60'});
        plates.plateTypes.push({value: 40, name:'Speed 40'});
    }

    const vehicleClasses = await axios.get('data/vehicles/classes.json');
    if (vehicleClasses) {
      const classes = vehicleClasses.data;
      classes.forEach((vc:any, index:number) => {
        const name = vc.Filename.split('.')[0];
        const tokens = name.split('_');
        const l = tokens.length;
        // name may contain underscore
        vc.minAxles = parseInt(tokens[l-2]);
        vc.maxAxles = parseInt(tokens[l-1]);
        vc.path = "data/vehicles/" + vc.Filename;
        vc.index = index;
        
      });
      common.axles.vehicleClasses = classes;
    }

    const changelog = await axios.get('data/changelog.json');
    if (changelog) {
      common.app.changelog = changelog.data;
    }

    const configJson = await axios.get('data/dummy-config.json');
    if (configJson) {
      common.axles.defaultWarpingConfig = configJson.data;
    }

    const reply = await axios.get('data/thai-provinces.txt');
    data = reply?.data;
    if (data) {
      data = data.match(/[^\r\n]+/g);
      data = data.map((ex:string) => ex.split('\t'));
      common.plates.thaiProvinces = data.map((dt:string[]) => this.getProvince(dt));
      const unk = new ProvinceData();
      unk.iso = '100';
      unk.abbreviation = '?';
      unk.localName = '?';
      unk.region = '?';
      unk.province = '?';
      common.plates.thaiProvinces.push(unk);
      const all = new ProvinceData();
      all.iso = '101';
      all.abbreviation = 'ALL';
      all.localName = 'ALL';
      all.region = 'ALL';
      all.province = 'ALL';
      common.plates.thaiProvinces.push(all);
    }



    } catch (ex) {
      console.error('failed to load static resources:', ex);
    }
  }

  /**
   * 
   * @param filename - chars filename
   */
  getChars = async (filename: string) => {
    try {
      const sauChars = await axios.get(filename);
      const sauToLatin = sauChars.data;
      const chars = [];  
      for( const sau in sauToLatin) {
        if (sauToLatin.hasOwnProperty(sau)) {
          const latin = sauToLatin[sau]; 
          chars.push({latin, arabic: sau })
        }
      } 
      return chars;
    } catch (ex) {
      console.error('failed to get chars:', ex);
      return [];
    }
  }

  getProvince = (tokens: string[]): ProvinceData => {
    try {
      const p = new ProvinceData();
      p.province = tokens[0];
      p.abbreviation = tokens[1];
      p.iso = tokens[2].split('-')[1];
      p.localName = tokens[3];
      p.region = tokens[4];
      return p;
    } catch (ex) {
      console.error('failed to parse province:', ex);
      return new ProvinceData();
    }
  }

  /**
   * update a list row with changes from the selected record
   * @returns 
   */
  updateCurrentRow = () => {
    try {
      const rec = common.plates.selectedRow;
      const selectedRecord = common.plates.selectedRecord;
      const audit = common.plates.settings.auditMode;
      // guard - should not happen
      if (!rec || !selectedRecord)
        return;
  
      const bccm = selectedRecord?.bccm;
  
      const pre = rec.TagCount;
      const ants = selectedRecord?.Annotations;

      // update vehicle types (used in filtering list)
      const vehicleTypes = ants?.map((a:any) => a.VehicleType?.value);
      rec.vehicleTypes = vehicleTypes;
      const occluded = ants?.find((a:any) => a.ExtraType?.value !== 0);
      rec.occluded = !!occluded;
      rec.TagCount = ants?.length;
      // WTT-392
      // rec.tagged = rec.TagCount > 0;
      rec.tagged = this.statistics.strictlyTagged(ants);
      rec.PreTagged = platesService.isPreTagged(ants);
      rec.status = rec.tagged ? "tagged" : "not-tagged";
      if (rec.PreTagged)
        rec.status = "pre-tagged";
  
      // add second token - bccm
      rec.status += bccm ? '+bccm' : '+';
  
      // third token - vehicle tagging exist 
      const vehiclized = common.plates.vehicles?.length > 0;
      rec.status += vehiclized ? '+vehicles' : '+';

      const insided = common.plates.insides?.length > 0;
      rec.status += insided ? '+insides' : '+';

      const lighted = common.plates.lights?.length > 0;
      rec.status += lighted ? "+lights" : "+";

      const haz = common.plates.hazards?.length > 0;
      rec.status += haz ? "+hazards" : "+";
     
      const chars = ants.find((a:any) => a.CharsPoly );
      rec.status += !!chars ? '+chars': '+';

      const reflective = common.plates.reflectives?.length > 0;
      rec.status += reflective ? "+reflectives" : "+";

  
  
  
      // update tagged counter (approx - periodicamente aggiorna)
      if (pre === 0 && rec.tagged)
        common.plates.taggedRecords++;
      // WTT-392 - flawed logic (an approximation)
      if (pre > 0 && !rec.tagged)
        common.plates.taggedRecords--;
  
      rec.state.problematic = common.plates.selectedRecord?.Problematic;
      rec.plateNumber = rec.TagCount === 0 ? '' : ants[0].PlateNumber?.value;
      // IMG-266 - include all plate numbers in record
      rec.plateNumbers = ants.map((a:any) => a.PlateNumber.value);
      rec.match =  this.getPlateMatch();
      common.notify('PlateStateUpdated');
    } catch(ex) {
      console.error('failed to update current record state:', ex);
    }
  }

  /**
   * returns plate match status for the current record
   * @param rec 
   * @returns 
   */
  getPlateMatch = (): PlateMatch => {
    try {
      let plate = common.plates.headerPlateNumber;
      if (!plate)
        return PlateMatch.NoPlate;

      plate = plate.split('-')[0];

      const ants = common.plates.selectedRecord?.Annotations;
      for (let i = 0; i < ants.length; i++) 
        if (ants[i]?.PlateNumber?.value === plate)
          return PlateMatch.Match;

      return PlateMatch.NoMatch;

    } catch (ex) {
      console.error('failed to get plate match:', ex);
      return PlateMatch.NoMatch;
    }
  }

  /**
   * plates.nation = egypt 
   */
  // egyptize = async () => {
  //   try {
  //     for (let i = 0; i < common.plates.records.length; i++) {
  //       const id = common.plates.records[i]._id;
  //       await this.egyptizeSingle(id);
  //     }
  //     console.log('done');

  //   } catch (ex) {
  //     console.error('failed to egyptize:', ex);
  //   }
  // }

//   egyptizeSingle = async (id: string) => {
//     try {
//       if (!id) throw(new Error('no Id to getRecord'));
//       const serverUrl = process.env.REACT_APP_SERVER_URL;
//       const url = `${serverUrl}/items/${id}`;
  
//       // clean current data
//       common.plates.selectedAnnotation = null;
//       common.plates.selectedRecord = null;
//       common.notify('AnnotationSelected');
  
  
//       const response = await axios.get(url, {headers: {'Authorization': common.loginToken}});
//       const native = response.data;
//       const ants = native?.image_library?.human?.plates?.Annotations;
//       if (!ants)
//         return;

//       let changed = false;

//       //     if (plates && plates.Annotations && !Array.isArray(plates.Annotations))
// //       plates.Annotations = [plates.Annotations];
//       if (Array.isArray(ants)) {
//         ants.forEach((ant:any) => {
//           if(ant?.Nation?.value === '253') {
//             ant.Nation.value = 'EGY';
//             changed = true;
//           }
//         });        
//       } else {
//         if(ants?.Nation?.value === '253-EGY') {
//           ants.Nation.value = 'EGY';
//           changed = true;
//         }
//       }

//       if (!changed)
//         return;




//       const url2 = `${serverUrl}/items/${id}`;
//       const putReply = await axios.put(url2, native, {headers: {'Authorization': common.loginToken}});

    

//     } catch (ex) {
//       common.relogIfTokenExpired(ex);
//       console.error('failed to egyptize single:', ex);
//     }
//   }

  /**
   * null if annotations are valid, otherwise error string
   * @returns 
   */
  getAnnotationValidity = (): string | null => {
    try {
      const ants = common.plates.selectedRecord?.Annotations || [];
    
      // at the moment - invalid if char height === 0 && plate number is not empty
      // plates validation
      for (let i  = 0; i < ants.length; i++) {

        const ant = ants[i];
        if (!ant) 
          return 'Internal plate tagging error, please retag';

            // if plate was set with at least on character
        // but char height was not set
        let plate = ant?.PlateNumber?.value || '';
        plate = plate.replaceAll('?', '');
        const plateType = ant.PlateType?.value || 0;
        // diamond plate type does not require char height
        if (!ants[i]?.CharHeight?.value && plate && plateType !== 4)
          return 'Set char height to proceed';

        const nation = ant.Nation?.value || '';
        const plateFace = ant.Category?.value || '';
        if (nation === 'ARE' && !plateFace)
          return 'Set plate face';

        // WTT-356
        if (nation === 'SAU' && !plateFace)
          return 'Set plate face';

        // WTT-424
        const ip = ant.InternalPoly.value;
        const w = ip[1] - ip[3];
        const h = ip[2] - ip[0];
        if (w < 0)
          return "Internal polygon width is inverted";

        if (h < 0)
          return "Internal polygon height is inverted";
        // WTT-424
        if (common.plates.settings.tagChars && ant.CharsPoly) {
          const chars = ant.CharsPoly.value;
          for (let j = 0; j < chars.length; j++) {
            if (chars[j].pristine)
              return 'Character polygon not set'; 
          }
        }

      }

      // vehicle validation
      const vehicles = common.plates.vehicles || [];
      for (let i = 0; i < vehicles.length; i++) {
        if (!vehicles[i].pose.value)
          return 'Set vehicle position to proceed';
      }

      // inside validation
      const insides = common.plates.insides || [];
      for (let i = 0; i < insides.length; i++) {
        const inside = insides[i];
        const passengers = inside.getPassengers();
        const occupied = passengers.filter(p => p.occupied.value);
        if (occupied.length === 0) {
          return 'Vehicle has no passengers';
        }
        const drivers = occupied.filter(p => p.driver.value);
        if (drivers.length !== 1) {
          return "No driver was assigned";
        }
      }

      const hazards = common.plates.hazards || [];
      for (const h of hazards) {
        if (h.ExtraType === 0 && !h.id) return "Hazard has no id  (Select a plateface)";
        if (h.ExtraType === 0 && !h.class) return "Hazard has no class (Select a plateface)";
      }

      const reflectives = common.plates.reflectives || [];
      for (const r of reflectives) {
        if (!r.type) return 'Reflective has no type';
      }

      return null;

    } catch (ex) {
      console.error('failed to get validity:', ex);
      return 'General validation error';
    }
  }

  getInsideArray = (item:InsideItem) => {
    try {
      return [item.occupied.value ? 1 : 0, 
        item.driver.value ? 1 : 0,
        item.beltVisible.value ? 1 : 0, 
        item.phoneVisible.value ? 1 : 0];
    } catch (ex) {
      console.error('failed to get inside array:', ex);
    }
  }

  /**
   * validate annotations 
   */
  validateAnnotations = () => {
    try {
      const validationError = this.getAnnotationValidity();
      const valid = validationError === null;
      common.plates.annotationsAreValid = valid;
      common.plates.validationError = validationError;
      common.notify("PlatesValidityChanged");
    
   
    } catch (ex) {
      console.error('failed on validation annotation:', ex);
    }
  }

  copyPlateListToClipboard = () => {
    try {
      const recs = common.plates.records;
      const clipped = recs.map((r, index) => `${index+1}\t${r.index}\t${r._id}\t${r.order ? r.order[0] : ''}\t${r.order ? r.order[1] : ''}\t${r.status}`);
      const alias = common.plates.datasetAlias || 'No alias';
      const time = new Date().toLocaleString();
      const total = clipped.length;
      clipped.splice(0, 0, `${alias} ${time} total:${total}`);

      const joint = clipped.join('\n');
      copy(joint);

    } catch (ex) {
      console.error('failed to copy plates to clipboard:', ex);
    }
  }

  /**
   * nation was changed - update char page
   */
  handleNationChanged = () => {
    try {
      const ant = common.plates.selectedAnnotation;
      const nation = ant?.Nation?.value || null;
      common.plates.charPageUrl = common.plates.charPageUrls[nation] || null;
      common.notify('PlatesCharPageChanged');
  
    } catch (ex) {
      console.error('failed to handle nation changed:', ex);
    }
  }

  /**
   * scarica l'immagine attuale
   */
  async downloadRawImage() {
    try {
      const FileSaver = require('file-saver');
      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      const id = common.app.context.id;
      const imageUrl = `${serverUrl}/items/${id}?${common.imageToken}&raw`;
      FileSaver.saveAs(imageUrl,`${id}.jpg`);
    } catch (ex) {
      console.error('failed to download raw image:', ex);
    }
  }

}

const platesService: PlatesService = new PlatesService();
export default platesService;